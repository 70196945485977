@font-face {
  font-family: 'Inter';
  src: url('InterVariable.woff2') format('woff2-variations');
  font-weight: 400 700;
}

body {
  margin: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f4f9;
  font-family: 'Inter', sans-serif;
}

.logo-name {
  align-items: center;
  font-size: 25px;
  font-weight: bold;
  width: 90%;
  margin: 0 auto;
  color: #2c3e50;
}

.logo {
  height: 32px;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 32px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  overflow: auto;
  width: 90%;
  border-radius: 25px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 0 15px 1px rgba(0,0,0,0.1);
  flex-grow: 1;
}

.input-section {
  flex: 0.4;
  padding: 40px;
  padding-right: 0px;
}

.output-section {
  flex: 0.6;
  padding: 40px;
}

.title {
  color: #2c3e50;
  margin-bottom: 16px;
}

.description {
  color: #7f8c8d;
  margin-bottom: 40px;
}

.description a {
  font-weight: 500;
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}

.input-group {
  width: 48%;
}

#marital-input-group {
  width: 100%;
}

.label {
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 8px;
}

.select,
.text-input {
  font-family: 'Inter', sans-serif;
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ddd;
  font-size: 16px;
  margin-top: 7px;
  color: #2c3e50;
  transition: all 0.3s ease;
}

.select:hover,
.text-input:hover {
  border-color: #0866ff;
}

.select {
  height: 45px;
}

.text-input {
  width: calc(100% - 24px);
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ddd;
  font-size: 16px;
}

.segmented-controls {
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  height: 45px;
  margin-top: 7px;
}

.segmented-control {
  flex: 1;
  background-color: #f0f4f9;
  color: #2c3e50;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.segmented-control:not(:last-child) {
  border-right: 1px solid #ddd;
}

.segmented-control:hover {
  background-color: #0866ff;
  color: white;
}

.radio-input {
  display: none;
}

.radio-input:checked + .segmented-control {
  background-color: #0866ff;
  color: white;
}

.calculate-button {
  font-family: 'Inter', sans-serif;
  width: 100%;
  padding: 12px 20px;
  border: none;
  font-weight: 500;
  border-radius: 6px;
  background-color: #0866ff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.calculate-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  text-align: center;
}

.output-section {
  flex: 0.6;
  padding: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
}

.party-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f4f9;
  border-radius: 17px;
  padding: 30px;
  padding-bottom: 0;
  height: 95%;
}

.party {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 30%;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  align-self: center;
}

.party p {
  font-size: 30px;
  font-weight: bold;
  color: #2c3e50;
}

.income-change,
.tax-change {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid #ddd;
  height: fit-content;
}

.income-change p,
.tax-change p {
  margin: 0;
  font-size: 16px;
  color: #2c3e50;
}

.income-change p:last-child,
.tax-change p:last-child {
  font-weight: bold;
}

.change {
  justify-content: space-between;
  align-items: left;
  padding: 10px 0;
  border-top: 1px solid #ddd;
  width: 100%;
  margin-bottom: 0;
  height: 35%;
}

.dynamic-numbers {
  font-size: 100px;
  margin: 0 auto;
  display: block;
  width: fit-content;
  color: var(--dynamic-color, #2c3e50);
  font-variant-numeric: tabular-nums;
  font-weight: 700;
}

.dynamic-numbers.positive {
  --dynamic-color: green;
}

.dynamic-numbers.negative {
  --dynamic-color: red;
}

.secured-info {
  text-align: center;
  margin-bottom: 0;
  color: #7f8c8d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.material-symbols-rounded {
  font-size: 20px;
  margin-right: 3px;
  vertical-align: center;
}

.share-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: #f0f4f9;
  border-radius: 60px;
  margin-top: 20px;
  margin-bottom: 3px;
}

.share-text {
  color: #2c3e50;
  font-weight: bold;
  font-size: 15px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 0;
}

.share-icon {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto 25px;
}

.credit-disclaimer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 47px;
}

.credit {
  width: fit-content;
  margin-right: 20px;
}

.credit-text {
  font-size: 12px;
  padding: 15px;
  background-color: white;
  border-radius: 60px;
  text-decoration: none;
  color: #2c3e50;
}

.disclaimer {
  font-size: 12px;
  color: #2c3e50;
  text-align: right;
  margin: 0;
}

@media (max-width: 1570px) {
  .container {
    width: 90%;
  }
}

@media (max-width: 1180px) {
  .container {
    width: 95%;
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    padding: 10px;
  }
  
  .credit-disclaimer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .credit {
    width: fit-content;
    margin-bottom: 35px;
  }
  
  .credit-text {
    font-size: 12px;
    padding: 15px;
    background-color: white;
    border-radius: 60px;
    text-decoration: none;
  }
  
  .disclaimer {
    font-size: 12px;
    text-align: center;
    margin: 0;
  }
  
}

@media (max-width: 1115px) {
  .container {
    width: 97%;
  }

  .select,
  .text-input,
  .segmented-controls,
  .calculate-button {
    border-radius: 15px;
  }

}

/* Mobile styles */
@media (max-width: 1095px) {
  .app-container {
    width: 100%;
    padding: 20px;
  }

  .logo {
    margin-top: 25px;
    margin-left: -31px;
    height: 55px;
  }

  body {
    height: auto;
    justify-content: initial;
  }

  .container {
    flex-direction: column;
    width: 90%;
    height: auto;
    padding: 30px;
    margin-bottom: 15px;
  }

  .container h1 {
    font-size: 55px;
  }

  .description {
    font-size: 30px;
  }

  .label {
    font-size: 30px;
  }

  .input-section,
  .output-section {
    padding: 20px;
    justify-content: center;
  }

  .input-row {
    flex-direction: column;
    margin-bottom: 0;
  }

  .input-group {
    width: 100%;
    margin-bottom: 40px;
  }

  .segmented-controls {
    height: auto;
    flex-wrap: wrap;
    font-size: 30px;
    align-items: center;
  }

  .segmented-control {
    padding-top: 23px;
    height: 70px;
    align-items: center;
  }

  .select {
    font-size: 30px;
    height: 90px;
  }
  
  .material-symbols-rounded {
    font-size: 30px;
  }

  .error-message {
    font-size: 30px;
  }

  .calculate-button {
    font-size: 30px;
    height: 90px;
  }

  .secured-info {
    font-size: 27px;
    margin-bottom: 50px;
  }
  
  .share-buttons {
    margin-bottom: 20px;
  }

  .share-text {
    font-size: 30px;
  }

  .share-icon {
    width: 65px;
    height: 65px;
  }

  .party-column {
    justify-self: center;
    align-items: center;
    height: auto;
    padding: 20px;
    width: 82%;
  }

  .party p {
    font-size: 35px;
  }

  .change {
    height: auto;
    padding: 15px 0;
    font-size: 30px;
  }

  .credit {
    width: fit-content;
    margin-left: 20px;
    text-align: center;
    margin-bottom: 38px;
  }  

  .credit-text {
    font-size: 25px;
    padding: 25px;
    text-align: center;
  }

  .disclaimer {
    width: 90%;
    margin-bottom: 15px;
    font-size: 20px;
    text-align: center;
  } 
}